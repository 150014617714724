@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

body {
  background-color: black !important;
  color: #fff !important;
  font-family: "Inter", sans-serif;
}
.Inter {
  font-family: "Inter", sans-serif !important;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #8c8c8c !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #8c8c8c !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #8c8c8c !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #8c8c8c !important;
}
img {
  max-width: 100% !important;
}
.QuickExchange {
  font-size: 60px;
  color: white;
}
p.text-base.lg\:text-xl.inter.font-\[400\].lg\:pr-24.text-white.mt-2.lg\:mt-4 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.QuickExchangeH {
  font-size: 50px;
  line-height: 70px;
  font-family: Inter, sans-serif;
  margin-bottom: 7px;
}
.swapcard {
  border-radius: 20px;
}
button.accordion-button.collapsed {
  background: #191919 !important;
  border-radius: 0 !important;
  border: 0 !important;
  padding: 20px 30px;
  color: white;
  margin-bottom: 0 !important;
}
.accordion-item {
  background-color: transparent !important;
  margin-bottom: 20px !important;
  border: none !important;
}
.accordion-button:not(.collapsed) {
  background: #191919 !important;
  border-radius: 0 !important;
  border: 0 !important;
  padding: 20px 30px;
  color: white;
  margin-bottom: 0 !important;
}
.accordion-body {
  border: 0 !important;
  background: #3c3c3c !important;
  padding: 20px 30px !important;
  margin: 0 0 20px !important;
  color: white;
  font-size: 16px;
  line-height: 24px;
}
.left-shape {
  padding: 16rem 0;
  width: 100%;
}
.opacity-1 {
  opacity: 1 !important;
}

body nav.lffsMm {
  height: auto !important;
}

body .gUtTyh {
  background: #000 !important;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}
.text-xl {
  font-size: 20px !important;
}
.text-2xl {
  font-size: 18px !important;
  line-height: 28px;
}
.links a {
  font-size: 25px;
  color: #e8ca7f !important;
}
.swapcard > div {
  max-width: 100%;
  padding: 0;
}
#swap-page > div {
  padding: 30px;
}
#swap-page button[font-weight="535"] {
  border-radius: 4px !important;
  background: linear-gradient(
      111deg,
      #8c5a22 14.86%,
      #b57e2d 27.3%,
      #e7ae38 38.28%,
      #faca5c 50.56%,
      #ffebbb 62.95%,
      #f9c95a 79.31%,
      #ebb238 89.89%,
      #ba832e 99.85%,
      #8c5922 109.28%
    ),
    #d9d9d9 !important;
  color: #000 !important;
  font-size: 16px !important;
  padding: 13px 0;
}

#swap-currency-input div:nth-child(2),
#swap-currency-output div:nth-child(2) {
  flex-direction: row-reverse;
  justify-content: space-between;
}
#swap-currency-input div:nth-child(2) > div,
#swap-currency-output div:nth-child(2) > div {
  display: inline-block !important;
  flex-grow: unset !important;
  width: calc(100% - 40px);
}
#swap-currency-input div:nth-child(2) > div input,
#swap-currency-output div:nth-child(2) > div input {
  max-height: initial !important;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 11px 16px;
  font-size: 16px;
  width: 100%;
}
div[style="user-select: none;"] {
  display: none;
}

#swap-page > div .rounded div,
#swap-page > div .rounded div {
  height: auto !important;
  background: transparent;
  padding-left: 0;
  padding-right: 0;
}

#swap-page > div div:nth-child(2) div::before,
#swap-page > div div:last-child div::before {
  content: none !important;
}

.common-btn,
#swap-button,
#confirm-swap-or-send,
#join-pool-button {
  border-radius: 5px;
  background: linear-gradient(
      111deg,
      #8c5a22 14.86%,
      #b57e2d 27.3%,
      #e7ae38 38.28%,
      #faca5c 50.56%,
      #ffebbb 62.95%,
      #f9c95a 79.31%,
      #ebb238 89.89%,
      #ba832e 99.85%,
      #8c5922 109.28%
    ),
    #d9d9d9 !important;
  padding: 11px;
  color: #000 !important;
  font-size: 16px;
  font-weight: 600;
}
.bnr-btn .common-btn {
  max-width: max-content !important;
  border-radius: 8px;
  padding: 15px;
  margin: auto;
}

.bnr-btn .common-btn {
  font-size: 16px !important;
  color: #000;
}
.font-size-3xl {
  font-size: 2.75rem;
  line-height: 1;
  font-weight: 600;
}

.market-data th {
  padding-bottom: 30px;
  color: #c2c2c2;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.market-data tbody tr:not(:last-child) td,
.market-data th {
  border-bottom: 1px solid #2c3144;
}

.market-data th:first-child {
  width: 450px;
}

.market-data th:nth-child(2) {
  width: 300px;
}

.market-data td {
  padding: 5px 0;
  color: #bfbfbf;
}

.market-data td:first-child {
  padding: 20px 0;
}

.wallet-box {
  background: #1a1a1a !important;
  border: 1px solid #fff;
  border-radius: 20px;
  padding: 30px;
}
.rounded-md {
  border-radius: 0.375rem;
}

/* .text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
} */

.faq-questions {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.faq-questions div[data-testid="flowbite-accordion"] {
  border-radius: 0 !important;
  border: 0 !important;
}

.faq-questions button:focus {
  box-shadow: none !important;
}

.faq-questions button {
  background: #191919 !important;
  border-radius: 0 !important;
  border: 0 !important;
  padding: 34px 50px;
  margin-bottom: 20px !important;
}

.faq-questions button svg {
  fill: #fff;
}

.faq-questions h2 button {
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.faq-questions div[data-testid="flowbite-accordion-content"] {
  border: 0 !important;
  background: #3c3c3c !important;
  padding: 42px !important;
  margin: -20px 0 20px !important;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  background-image: url(../assets/images/down-arrow.png);
  background-size: 16px;
}

.bg-black,
.faq-questions + div {
  background: #000 !important;
}

.faq-questions + div {
  padding-bottom: 0 !important;
  padding: 0 !important;
}

.f-18 {
  font-size: 20px;
  color: #fff;
  line-height: 30px;
}

footer .f-18 {
  font-size: 14px;
  color: #fff;
  line-height: 24px;
} 

.text-theme {
  color: #e8ca7f !important;
}

.faq-questions + div a {
  color: #fff;
}

.site-copyright {
  border-top: 1px solid #fff;
  padding: 24px;
  text-align: center;
}

.site-copyright p {
  margin: 0;
}

#swap-page > div div:nth-child(2) div:nth-child(2) {
  margin-bottom: 0px;
}

/* .bg-black #swap-currency-output button {
  color: #000;
}

.bg-black #swap-currency-output button path {
  stroke: #000;
}
.pool-page svg {
  stroke: #fff;
} */
/* .active {
  border-bottom: 4px solid #e8ca7f;
} */

.header > div:first-child {
  flex: auto;
}

.market-data tbody img[width="60"] {
  width: 40px;
}

#swap-page > div .rounded .round-arrow {
  width: auto;
  height: auto !important;
  border: 0;
}

#swap-page > div .rounded div[style="display: inline;"],
#swap-page > div .rounded div[style="display: inline;"] {
  width: auto;
}

.border-0 > div:first-child {
  border: 0;
}

nav .common-btn {
  width: 170px;
  border-radius: 4px;
}

span > div + svg path {
  stroke: #ffffff !important;
}

#open-settings-dialog-button[aria-label="Transaction Settings"] svg {
  display: none;
}

#open-settings-dialog-button[aria-label="Transaction Settings"] {
  background-image: url(../assets/images/sett.svg);
  background-position: 0;
  background-size: 100%;
  background-repeat: no-repeat;
  min-width: 30px;
  min-height: 30px;
}

.faq-questions + div {
  border-top: 1px solid #717171;
  padding-top: 80px !important;
}

.drop-btn {
  position: relative;
  width: 42px;
  height: 42px;
  background-color: #000;
  border-radius: 55px;
  border: 2px solid gray;
}

.dropdown-toggle::after {
  display: none;
}

button#dropdown-basic {
  padding: 0px !important;
  border-radius: 50px;
}

.dropdown-menu,
.bg-color {
  background-color: #373739;
}

.dropdown-menu a {
  transition: all 0.5s;
  border-radius: 4px;
}
.dropdown-menu a:hover,
.dropdown-menu a:focus {
  background: rgb(248 249 250 / 20%) !important;
}

.btn-copy {
  background: linear-gradient(
      111deg,
      #8c5a22 14.86%,
      #b57e2d 27.3%,
      #e7ae38 38.28%,
      #faca5c 50.56%,
      #ffebbb 62.95%,
      #f9c95a 79.31%,
      #ebb238 89.89%,
      #ba832e 99.85%,
      #8c5922 109.28%
    ),
    #d9d9d9 !important;
  padding: 10px;
  width: 230px;
}

.copy-code-input {
  width: 230px;
  border: 1px dashed #fff;
  background: #1a1a1a;
  padding: 10px 30px;
}

.cong-div {
  padding-top: 66px !important;
  justify-content: center;
}

.people-div {
  border: 2px solid goldenrod;
  border-radius: 18px;
  color: #c2c2c2;
}

.join-people {
  background: linear-gradient(
      111.25deg,
      #8c5a22 14.86%,
      #b57e2d 27.3%,
      #e7ae38 38.28%,
      #faca5c 50.56%,
      #ffebbb 62.95%,
      #f9c95a 79.31%,
      #ebb238 89.89%,
      #ba832e 99.85%,
      #8c5922 109.28%
    ),
    linear-gradient(0deg, #ffffff, #ffffff);

  align-items: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.featureList li:not(:last-child) {
  margin-bottom: 8px;
}
.featureList li {
  list-style: none;
}
.featureList svg {
  margin: 0 10px;
}

.dcoinLevel ::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  outline: 1px solid #d9d9d9;
  border-radius: 50px;
}
.dcoinLevel ::-webkit-scrollbar {
  width: 2px;
}
.dcoinGeneration ::-webkit-scrollbar {
  width: 2px;
}
.dcoinGeneration ::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  outline: 1px solid #d9d9d9;
  border-radius: 50px;
}

/* .summary-data {
  background: rgb(201 165 121 / 61%) !important;
  box-shadow: 0 0 20px 0 #fff;
} */

.summary-data th,.summary-data td{
  padding: 10px 20px;
}

.summary-data tbody tr:nth-child(odd) {
  background: #00000059;
}

/* tree structure  */
#simple-treeview,
#product-details {
  display: inline-block;
  color: #fff !important;
}

#product-details {
  vertical-align: top;
  width: 400px;
  height: 420px;
  margin-left: 20px;
}
.PhPDm,
.hbXxoz {
  list-style-type: none;
  display: flex;
  align-items: center !important;
  margin: 2px 0px;
}
#product-details > img {
  border: none;
  height: 300px;
  width: 400px;
}

#product-details > .name {
  text-align: center;
  font-size: 20px;
}

#product-details > .price {
  text-align: center;
  font-size: 24px;
}

.dark #product-details > div {
  color: #f0f0f0;
}

.hidden {
  visibility: hidden;
}
.sc-lnAgIa svg {
  display: none !important;
}
/* .dx-treeview-toggle-item-visibility {
  position: absolute;
  cursor: pointer;
  font: 22px/22px DXIcons;
  text-align: center;
  color: #fff6f6 !important;
  width: 21px;
  height: 32px;
  top: 0;
  left: -4px;
} */
/* .dx-treeview-item.dx-state-hover {
  color: #fff !important;
} */
/* .dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item {
  background-color: unset !important;
  color: #fff;
} */
.dx-treelist .dx-column-lines > td {
  border-left: none !important;
  border-right: none !important;
  border: none !important;
  background-color: #252525 !important;
  color: #fff !important;
}
.dx-treelist .dx-column-lines > td:last-child {
  border-left: none !important;
  border-right: none !important;
  border: none !important;
  background-color: #252525 !important;
  font-size: 16px !important;
  color: #ff0000 !important;
}
.dx-treelist-borders > .dx-treelist-filter-panel,
.dx-treelist-borders > .dx-treelist-headers {
  border-top: none !important;
  border: none !important;
}
.dx-treelist-headers .dx-treelist-table .dx-row > td {
  border: none !important;
  background-color: #252525 !important;
  color: #fff !important;
}
.dx-treelist-borders .dx-treelist-rowsview,
.dx-treelist-headers + .dx-treelist-rowsview,
.dx-treelist-rowsview.dx-treelist-after-headers {
  border-top: 1px solid rgb(80, 80, 80) !important;
}
.dx-treelist-borders > .dx-treelist-headers,
.dx-treelist-borders > .dx-treelist-rowsview,
.dx-treelist-borders > .dx-treelist-total-footer {
  border-left: none !important;
  border-right: none !important;
}
.dx-treelist-borders > .dx-treelist-rowsview,
.dx-treelist-borders > .dx-treelist-total-footer {
  border-bottom: none !important;
}
.dx-treelist-rowsview .dx-row {
  border-top: none !important;
  border-bottom: none !important;
}

.dx-treelist-rowsview .dx-treelist-expanded span:before {
  content: "\f001";
  display: block;
  margin-left: -5px;
  margin-top: -8px;
  color:  #ECB239 !important;
  cursor: pointer;
  font: 22px/22px DXIcons !important;
  height: 32px;
  left: -4px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 21px;
}
.dx-treelist-rowsview .dx-treelist-collapsed span::before {
  content: "\f04e";
  position: absolute;
  display: block;
  width: 18px;
  top: 50%;
  left: 0;
  font: 22px/22px DXIcons !important;
  color: #ECB239 !important;
  margin-left: -6px;
  margin-top: -8px;
}
.dx-treelist-rowsview .dx-treelist-table-fixed .dx-treelist-icon-container {
  position: relative;
  left: -10px;
  top: -2.5px;
  color: green;
}
.cNJiyB {
  list-style-type: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: center !important;
  margin: 2px 0;
}
.swapTab {
  text-decoration: none;
  color: #9b9b9b;
  font-weight: 600;
}
.border-all {
  border: 1px solid #fff;
  border-top-width: 3px;
}

.f-24 {
  font-size: 24px;
}

.f-40 {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}

.f-20 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.following-link {
  list-style: none;
  padding: 0;
}
.following-link li {
  padding: 0 20px;
}

.following-link li a {
  color: #fff;
  font-size: 36px;
}

.people-div h1.text-white.mt-2 {
  font-size: 30px;
}

#employees {
  max-height: 440px;
}
table.dx-treelist-table.dx-treelist-table-fixed {
  background: #212529 !important;
  color: white !important;
}
.teamCount {
  font-weight: 535;
  font-size: 30px;
}

.swap_btn {
  background: linear-gradient(
      111deg,
      #8c5a22 14.86%,
      #b57e2d 27.3%,
      #e7ae38 38.28%,
      #faca5c 50.56%,
      #ffebbb 62.95%,
      #f9c95a 79.31%,
      #ebb238 89.89%,
      #ba832e 99.85%,
      #8c5922 109.28%
    ),
    #d9d9d9 !important;
}

.dMbMzL {
  background: linear-gradient(
      111deg,
      #8c5a22 14.86%,
      #b57e2d 27.3%,
      #e7ae38 38.28%,
      #faca5c 50.56%,
      #ffebbb 62.95%,
      #f9c95a 79.31%,
      #ebb238 89.89%,
      #ba832e 99.85%,
      #8c5922 109.28%
    ),
    #d9d9d9 !important;
}

.size_stak {
  max-width: 600px;
  align-items: center;
  background-color: #212529;
  justify-content: center;
}

.inside_stak {
  max-width: 550px;
  background-color: #000;
  align-items: center;
}

.dx-treelist-headers .dx-treelist-table .dx-row>td .dx-sort, .dx-treelist-headers .dx-treelist-table .dx-row>td .dx-sort-indicator, .dx-treelist-headers .dx-treelist-table .dx-row>td:hover .dx-treelist-text-content{
  color: #fff !important;
}
/* 
.bg-1 {
  background: #198754 !important;
}
.bg-2{
  background: #fd0363 !important;
}
.bg-3 {
  background: #4b49ac !important;
}
.bg-4 {
  background: #ff7247 !important;
}
.bg-5 {
  background: #007bff !important;
}
.bg-6{
  background: #1bc9b7 !important;
} */

@media(min-width: 1024.98px){
  .bottom-shape {
    background-image: url(../assets/images/round-shape.png);
    background-position: left -300px top 0;
    background-size: 64%;
    background-repeat: no-repeat;
    padding: 40px 0 400px;
    margin: 150px 0 0;
    width: 100%;
  }
  .steps:not(:last-child):after {
    /* background: url(../assets/images/divider.svg) no-repeat 50%; */
    background-size: contain;
    content: "";
    position: absolute;
    top: 30px;
    right: -70px;
    width: 160px;
    height: 30px;
    display: block;
  }
}

@media (min-width: 1439.98px) {
  .bottom-shape {
    background-size: 38%;
    padding: 40px 0 380px;
  }
  .one-app {
    margin: -200px 0 150px;
  }
  .font-size-3xl {
    font-size: 2.75rem;
  }
}

@media (min-width: 1199.98px) {
  .container {
    max-width: 1280px;
  }
  .walletconnect-block {
    margin-top: -150px;
    margin-bottom: 200px !important;
  }
  .one-app {
    margin: -100px 0 150px;
  }
  .left-shape {
    background: url(../assets/images/round-shape.png),
      url(../assets/images/round-shape.png);
    background-position: left -600px bottom, right -500px top 0;
    background-repeat: no-repeat, no-repeat;
  }
  .faq-questions {
    margin-bottom: 10rem;
  }
}

@media (max-width: 1199.98px) {
  .walletconnect-block {
    margin-bottom: 100px !important;
  }
  .py-80 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .left-shape {
    padding: 120px 0;
  }
  .market-data table.w-100 {
    width: 1200px !important;
  }
}

@media (max-width: 991.98px) {
  .following-link li {
    padding: 0 10px;
  }
  .following-link li a {
    font-size: 22px;
  }
  .market-data th:first-child {
    width: auto;
  }
  .title-text {
    font-size: 55px !important;
    line-height: 62px !important;
  }
  .left-shape {
    padding: 50px 0;
    width: 100%;
  }
  .QuickExchangeH {
    font-size: 40px;
    line-height: 48px;
  }
  .font-size-3xl {
    font-size: 40px;
    line-height: 1;
  }
  .site-copyright {
    padding: 30px 0;
  }
  .py-80 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .sub-title {
    font-size: 22px !important;
    line-height: 30px !important;
  }
  .bnr-btn .common-btn {
    margin: auto;
  }
  .text-2xl {
    font-size: 20px !important;
    line-height: 28px;
  }
  #swap-page > div {
    padding: 20px;
  }
  #swap-page > div .rounded div,
  #swap-page > div .rounded div {
    padding-left: 0;
    padding-right: 0;
  }
  .accordion-button:not(.collapsed),
  .accordion-button.collapsed {
    padding: 20px 20px !important;
  }
  .faq-questions h2 button {
    font-size: 18px;
    padding: 20px 20px;
  }
  .accordion-body {
    font-size: 16px !important;
    line-height: 26px !important;
    padding: 20px !important;
  }
  .text-xl {
    font-size: 16px !important;
  }
  .site-header {
    padding: 10px 0px !important;
  }
}

.border_gray {
  border-bottom: 1px solid #484848;
}

@media (max-width: 767.98px) {
  .f-40{
    font-size: 28px;
    line-height: 36px;
  }
  body {
    padding-bottom: 70px;
  }
  .title-text {
    font-size: 36px !important;
    line-height: 42px !important;
  }
  .header > div:first-child div img {
    height: 30px;
  }
  #swap-currency-input div:nth-child(2),
  #swap-currency-output div:nth-child(2) {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  #swap-currency-input div:nth-child(2) > div input,
  #swap-currency-output div:nth-child(2) > div input {
    margin-top: 16px;
  }
  #swap-currency-input div:nth-child(2) > div,
  #swap-currency-output div:nth-child(2) > div {
    width: 100%;
  }
  .QuickExchangeH {
    font-size: 30px;
    line-height: 38px;
  }
  .font-size-3xl {
    font-size: 30px;
    line-height: 1;
  }
  .f-logo {
    width: 200px;
  }
}


@media screen and (max-width: 768px) {
  .size_stak {
    max-width: 650px;
    background-color: #212529;
  }

  .inside_stak {
    max-width: 400px;
    margin: auto;
    align-items: center;
  }

  .swap_newbtn {
    margin-top: 10px;
  }
}
.dx-treelist-rowsview .dx-treelist-empty-space {
  width: 4px !important;
}

.TeamViewCard.card {
  border: 3px solid #ECB239 !important;
  border-radius: 15px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%; 
  box-shadow: rgb(201 165 121 / 53%) 0 2px 12px 0px;
}

.form.border-dark.bg-dark.text-light {
  box-shadow: rgb(201 165 121 / 53%) 0 2px 12px 0px;
  border: 3px solid #ECB239!important;
  border-radius: 12px;
  overflow: hidden;
}

