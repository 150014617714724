.ant-input {
  background-color: black !important;
  color: white !important;
  margin-bottom: 5px;
  font-size: 18px;
  border-radius: 8px !important;
  border: 1px solid #57667e;
}

.ant-input::placeholder {
  color: #5f6783 !important;
}

.ant-popover-arrow {
  visibility: hidden;
}

.ant-popover-title {
  color: white !important;
}

.ant-popover-inner-content {
  color: white !important;
}

.ant-popover-inner {
  min-width: 260px !important;
  min-height: 140px !important;
  border: 1px solid #21273a;
  background-color: #0e111b !important;
}

.ant-radio-group {
  margin-top: 10px;
}

.ant-radio-button-wrapper {
  background-color: #1f2639 !important;
  color: white !important;
  font-weight: 500;
  border-color: white !important;
}

.ant-radio-button-wrapper-checked {
  border-color: white !important;
  background-color: #363e54 !important;
}

.ant-radio-button-wrapper-checked::before {
  background-color: white !important;
}

.ant-modal-content {
  background-color: #000 !important;
  box-shadow: #c9a5794f 0px 2px 60px 0px;
  color: white !important;
  padding: 0px !important;
}

.ant-modal {
  border: 1px solid #363e54;
  width: 400px !important;
  border-radius: 10px;
}

.ant-modal-header {
  background-color: #000 !important;
}

.ant-modal-title {
  color: white !important;
  padding-top: 17px !important;
  margin-left: 20px !important;
}

.ant-modal-close-icon {
  color: #363e54 !important;
  transition: 0.3s;
}

.ant-modal-close-icon:hover {
  color: white !important;
}
.typeWriter {
  display: inline-flex;
}

.typeWriter span {
  word-break: break-all;
  height: 1.2em;
  width: 0%;
  overflow: hidden;
  animation: t 1s linear infinite alternate;
}
.typeWriter span:before {
  content: " ";
  display: inline-block;
}

@keyframes t {
  90%,
  100% {
    width: 100%;
  }
}

.App {
  text-align: center;
}

header {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}

.logo {
  width: 180px;
  height: 70px;
}

.eth {
  height: 20px;
  padding-right: 10px;
}

.leftH {
  display: flex;
  align-items: center;
  gap: 20px;
}

.rightH {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.headerItem {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-weight: 500;
  transition: 0.3s;
  display: flex;
  align-items: center;
  color: white;
}

.headerItem:hover {
  /* background-color: rgb(34, 42, 58); */
  cursor: pointer;
  color: #8627e5;
}

.connectButton {
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 100px;
  color: #0000;
  font-weight: bold;
  transition: 0.3s;
}

.connectButton:hover {
  cursor: pointer;
  color: #ffff;
}

.mainWindow {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.link {
  text-decoration: none;
  color: white;
}

.tradeBox {
  width: 550px;
  background-color: #212529;
  backdrop-filter: blur(10px);
  min-height: 300px;
  border-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px;
  /* box-shadow: #c9a5794f 0px 2px 60px 0px; */
}

button[aria-label="Connect Wallet"] {
  background: linear-gradient(
    rgba(248, 241, 211, 1) 0%,
    rgba(201, 165, 121, 1) 100%
  ) !important;
}

.tradeBoxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  color: white;
  margin-bottom: 8px;
}

.cog {
  color: #ffffff;
  transition: 0.3s;
}

.cog:hover {
  color: white;
  rotate: 90deg;
  cursor: pointer;
}

.swapBox div:first-child div:nth-child(2),
.swapBox div:nth-child(3) div:nth-child(2) {
  width: 60%;
}

.swapBox input {
  height: 48px;
}

.switchButton {
  background: linear-gradient(
    rgba(248, 241, 211, 1) 0%,
    rgba(201, 165, 121, 1) 100%
  );
  width: 55px;
  height: 55px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 8px;
  color: #5f6783;
  border: 3px solid #0e111b;
  font-size: 12px;
  transition: 0.3s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 28%;
  border-radius: 50px;
}
.switchButton:after {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  background: url(https://cdn-icons-png.flaticon.com/512/7986/7986683.png);
  position: absolute;
  left: 0;
  right: 0;
  top: 12px;
  margin: auto;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: 100%;
  /* filter: brightness(0) invert(1); */
}
.switchButton img {
  display: none;
}
.switchBox {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 20px 0 50px;
  margin-bottom: -13px;
}
.swapBox {
  background: black;
  padding: 15px 20px;
  border-radius: 8px;
  border: 3px solid #ecb239 !important;
}
.tokenNameP {
  color: rgb(196, 169, 121);
  margin-left: auto;
  padding-left: 64px;
}
.swapBG {
  width: 100%;
  /* margin-top: 80px;
  padding-bottom: 50px; */
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
}
.blink_me {
  animation: blinker 1s linear infinite;
  color: red;
}
.anticon-setting {
  font-size: 22px;
}
.anticon svg {
  display: inline-block;
  font-size: 15px;
  color: white;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.dollarAmount {
  position: absolute;
  top: 75px;
  left: 14px;
  font-size: 10px;
  color: #5f6783;
}

.switchButton:hover {
  color: white;
  cursor: pointer;
}

.inputs {
  position: relative;
}

.assetOne {
  /* position: absolute; */
  min-width: 50px;
  height: 30px;
  /* background-color: #3a4157; */
  /* top: 36px;
  right: 20px; */
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  gap: 5px;
  font-weight: bold;
  font-size: 17px;
  padding-right: 8px;
  color: white;
}

.assetTwo {
  /* position: absolute; */
  min-width: 50px;
  height: 30px;
  /* background-color: #3a4157; */
  top: 228px;
  right: 20px;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  gap: 5px;
  font-weight: bold;
  font-size: 17px;
  padding-right: 8px;
  color: white;
}

.assetOne:hover {
  cursor: pointer;
}

.assetTwo:hover {
  cursor: pointer;
}

.assetLogo {
  /* border-radius: 50%; */
  height: 50px;
  margin-right: 10px;
}

.swapButton {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f8c885; */
  background: rgb(248, 241, 211);
  background: linear-gradient(
    rgba(248, 241, 211, 1) 0%,
    rgba(201, 165, 121, 1) 100%
  );
  width: calc(100%);
  height: 55px;
  font-size: 20px;
  border-radius: 12px;
  color: black;
  font-weight: bold;
  transition: all ease 0.5s;
  /* margin-bottom: 30px; */
  margin-top: 8px;
  margin-left: auto;
  width: 60%;
}
.swapButton:hover {
  background: linear-gradient(
    rgba(248, 241, 211, 1) 0%,
    rgba(201, 165, 121, 1) 80%
  ) !important;
}

.swapButton[disabled] {
  background-color: #e5c290;
  opacity: 0.4;
  color: black;
}
.rightArrowConfirm {
  width: 30px;
  filter: invert();
  margin-left: 26px !important;
}
.explorar_link {
  color: #ffff;

  text-decoration: none;
}
.explorar_link:hover {
  color: #c7a678;
  text-decoration: none;
}
.swapButton[disabled]:hover {
  cursor: not-allowed;
}

.swapButton:hover {
  cursor: pointer;
  background-color: #fec26e;
}

.tokenLogo {
  height: 40px;
  width: 40px;
}

.modalContent {
  border-top: 1px solid #363e54;
  /* padding-top: 20px; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.tokenChoice {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tokenChoice:hover {
  cursor: pointer;
  background-color: #f2e7c936;
}

.tokenChoice > .tokenChoice {
  background: transparent !important;
}

.tokenName {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
}

.tokenTicker {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 300;
  color: #51596f;
}
.successIcon {
  width: 36px !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}
:where(.css-dev-only-do-not-override-6j9yrn).ant-input:hover {
  border-color: #c7a678 !important;
}
:where(.css-dev-only-do-not-override-6j9yrn).ant-input:focus,
:where(.css-dev-only-do-not-override-6j9yrn).ant-input:focus-within {
  border-color: #c7a678 !important;
}
@media (max-width: 768px) {
  .tradeBox {
    width: 100%;
    padding: 15px;
  }
}
@media (max-width: 767.98px) {
  .logo {
    width: 120px;
    height: auto;
  }
  header {
    padding: 0;
  }
  .rightH .headerItem {
    display: none;
  }
  .leftH + .link {
    display: block;
    font-size: 14px;
  }
}
@media (max-width: 576.98px) {
  .swapBox .d-flex.justify-content-between {
    flex-direction: column;
    align-items: flex-start !important;
    margin-bottom: 0 !important;
  }
  .swapBox div:first-child div:nth-child(2),
  .swapBox div:nth-child(3) div:nth-child(2) {
    width: 100%;
  }
  .assetOne + div,
  .assetTwo + div {
    width: 60% !important;
    margin: -50px 0 20px;
  }
  .switchButton {
    right: 40%;
  }
  .assetOne,
  .assetTwo {
    height: auto;
    /* margin: 0 0 30px; */
  }
  .swapBox input {
    height: 40px;
  }
  .assetOne {
    align-items: center;
    font-size: 14px;
  }
  .switchBox {
    padding: 20px 0 41px;
  }
  .assetTwo {
    align-items: center;
    font-size: 14px;
  }
  .assetOne p {
    font-size: 13px;
  }
  .assetTwo p {
    font-size: 13px;
  }

  .switchButton {
    width: 46px;
    height: 46px;
  }
  .swapBox {
    padding: 16px;
  }
  .swapButton {
    width: 100%;
    margin-top: 15px;
  }
  .assetLogo {
    /* border-radius: 50%; */
    height: 38px;
    margin-right: 10px;
  }
  /* .swapBG {
    margin-top: 26px;
  } */
  .switchButton:after {
    content: "";
    top: 9px;
  }
  .tokenNameP {
    color: rgb(196, 169, 121) !important;
    margin-left: auto !important;
    padding-left: 52px !important;
  }
}

/* loader css */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.donut {
  display: inline-block;
  border: 4px solid rgb(255 248 248);
  border-left-color: #fcca74;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: donut-spin 1.2s linear infinite;
}

/* commom class css */
.css-1g5k5ll {
  min-width: 150px !important;
}

/* css of particals */

.snow-container {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  pointer-events: none;
}

.snowflake {
  position: absolute;
  background-color: yellow;
  border-radius: 50%;
  opacity: 0.8;
  pointer-events: none;
}

@keyframes fall {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
    transform: translateY(100vh);
  }
}

@keyframes diagonal-fall {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
    transform: translate(10vw, 100vh);
  }
}

div#radix-\:Rim\: {
  box-shadow: #c9a5794f 0px 2px 60px 0px;
  background: #000;
}

.border_gray {
  border-bottom: 1px solid rgb(72, 72, 72);
}

img.w-100 {
  height: 35px !important;
}

.tokenscroll-area {
  height: 300px;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #1f2639;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.LoaderRotate {
  display: inline-block;
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.maxButton {
  position: absolute;
  right: 8%;
  margin-top: 11px;
  background-image: linear-gradient(
    111deg,
    #8c5a22 14.86%,
    #b57e2d 27.3%,
    #e7ae38 38.28%,
    #faca5c 50.56%,
    #ffebbb 62.95%,
    #f9c95a 79.31%,
    #ebb238 89.89%,
    #ba832e 99.85%,
    #8c5922 109.28%
  );
  padding: 1px 7px;
  border: none;
  color: black;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 576.98px) {
  .maxButton {
    margin-top: 7px;
  }
}
@media (max-width: 767.98px) {
  .maxButton {
    right: 10%;
  }
}
@media (max-width: 399.98px) {
  .maxButton {
    right: 12%;
  }
}
